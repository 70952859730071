import { Component, OnInit, ViewChild } from '@angular/core';
import {OwlCarousel} from 'ngx-owl-carousel';
import {AppService} from "../app.service";
import {Router, ActivatedRoute} from "@angular/router";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-photo-gal',
  templateUrl: './photo-gal.component.html',
  styleUrls: ['./photo-gal.component.css']
})
export class PhotoGalComponent implements OnInit {
  @ViewChild('owlElement') owlElement: OwlCarousel
  mainPhoto:any;
  selectedItem:any;
  gallst:any={};
  basepath="https://api.sananda.in";
  galitm:any=[];
  gtag:any=[];
  galId:any;
  sName:any;
  constructor(private apiService: AppService, public activeRoute: ActivatedRoute, private router : Router, private spinnerService: Ng4LoadingSpinnerService) { }

  ngOnInit() {
    this.spinnerService.show();
   // this.mainPhoto='assets/images/large-pic/photo1.jpg'

 //   this.galId = this.activeRoute.snapshot.queryParams['gid'];

 this.activeRoute.queryParams.subscribe(params => {
  this.galId = params['gid'];
  this.sName = params['gnm'];
  this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  //location.reload();
  this.initialiseState(this.galId); // reset and set based on new parameter this time
});
    
setTimeout(function() {
  $("#a1").fadeOut('fast');
  $("#a11").fadeOut('fast');
 }, 5000);

let current = 0;
setInterval(function() {
  var divs = $(".roll").hide();
  divs.eq(current).fadeIn("normal");
  if (current < divs.length - 1)
    current++;
  else
    current = 0;
}, 5000);

// let current1 = 0;
// setInterval(function() {
//   var divs1 = $(".roll1").hide();
//   divs1.eq(current1).fadeIn("normal");
//   if (current1 < divs1.length - 1)
//     current1++;
//   else 
//     current1 = 0;
// }, 5000);
  
  }
  
  
  initialiseState(galId){
  
    this.apiService.photoGal(galId).subscribe(
      data => {
        console.log(data);
        this.gallst=data;
        
        this.galitm=this.gallst.galleryItem;
        this.selectedItem=this.gallst.galleryItem[0].image;
        this.gtag=this.gallst.tags;
        //this.image1=this.baseImgPath+'/image/journal/article?img_id='+this.str1.imageId;      
        this.spinnerService.hide();
      },
      error=>{
        console.log(error);   
        this.spinnerService.hide();
      });
    }

  openBig(cnt){
    console.log(cnt);
    this.selectedItem = cnt;
    //this.mainPhoto=cnt;
  }

}
