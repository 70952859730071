import { Component, OnInit } from '@angular/core';
import {Router,ActivatedRoute,NavigationEnd} from "@angular/router";
import {AppService} from "../app.service";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { MetaService } from 'ng2-meta';

@Component({
  selector: 'app-storypage',
  templateUrl: './storypage.component.html',
  styleUrls: ['./storypage.component.css']
})
export class StorypageComponent implements OnInit {
  linkId:any;
  storyDet:any={};
  baseImgPath ='https://api.sananda.in';
  storyDetImg:any;
  catid:any;
  strtagsl:any;
  strtags:any=[];
  relatedstr:any=[];
  constructor(private router : Router,public activeRoute: ActivatedRoute, 
    private metaService: MetaService, private apiService: AppService, private spinnerService: Ng4LoadingSpinnerService) {
   }

  ngOnInit() {
    this.metaService.setTitle('Sananda: '+this.storyDet.title);
    this.metaService.setTag('og:image',this.storyDetImg);
    this.spinnerService.show();
    this.linkId = this.activeRoute.snapshot.queryParams['link'];    

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      } 
      window.scrollTo(0, 0)     
  });

  setTimeout(function() {
    $("#a1").fadeOut('fast');
    $("#a11").fadeOut('fast');
   }, 5000);

  let current = 0;
  setInterval(function() {
    var divs = $(".roll").hide(); 
    divs.eq(current).fadeIn("normal");
    if (current < divs.length - 1)
      current++;
    else
      current = 0;
  }, 5000);

  let current1 = 0;
  setInterval(function() {
    var divs1 = $(".roll1").hide();
    divs1.eq(current1).fadeIn("normal");
    if (current1 < divs1.length - 1)
      current1++;
    else 
      current1 = 0;
  }, 5000);


    this.apiService.mainStory(this.linkId).subscribe(
      data => {
        console.log(data);
        this.storyDet=data;
        this.storyDetImg=this.baseImgPath+'/image/journal/article?img_id='+this.storyDet.imageId;   
        this.catid=this.storyDet.categoryId;
        this.strtags=this.storyDet.tags;
        this.strtagsl=this.storyDet.tags.length;

        //const imageUrl = baseUrl + '/path/to/image.png';

        this.metaService.setTitle('Sananda: '+this.storyDet.title);
        this.metaService.setTag('og:title', 'Sananda: '+this.storyDet.title);
        this.metaService.setTag('og:description', this.storyDet.description);
        this.metaService.setTag('og:image',this.storyDetImg);


        this.apiService.relCaro(this.catid, this.linkId).subscribe(
          data => {
              console.log(data);
              this.relatedstr=data;
              this.spinnerService.hide();
          },
          error=>{
            console.log(error);   
            this.spinnerService.hide();
          });
      },
      error=>{
        console.log(error);   
      });

      
  }
  closePop(){
    var x = document.getElementById("myModal");
    x.style.display = "none";
      document.getElementById('myModal').className += ' popNone';
      document.getElementById('myModal').classList.remove("show");

  }
  goToStory(linkid){
    // this.router.navigate(['section'],{ queryParams: {status: 'false'}});
    this.router.navigate(['story'],{ queryParams: {link: linkid}});
  }
  
  goToSearch(tag){
    this.router.navigate(['search'],{ queryParams: {key: encodeURIComponent(tag)}});
  }
}
