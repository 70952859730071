import { Component, OnInit, ViewChild } from '@angular/core';
import {AppService} from "../app.service";
import {Router, ActivatedRoute} from "@angular/router";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-event-landing',
  templateUrl: './event-landing.component.html',
  styleUrls: ['./event-landing.component.css']
})
export class EventLandingComponent implements OnInit {
  basepath="https://api.sananda.in";
  selectedItem:any;
  eventlst:any=[];
  cstart:number=0;
  cend:number=8;
  evId:any;
  loadmoretxt:any="LOAD MORE";
  sName:any;
  evitm:any=[];
  constructor(private apiService: AppService, public activeRoute: ActivatedRoute, private router : Router, private spinnerService: Ng4LoadingSpinnerService) { }

  ngOnInit() {
    this.activeRoute.queryParams.subscribe(params => {
      this.evId = params['sectionid'];
      this.sName = params['secName'];
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      //location.reload();
      this.initialiseState(this.evId); // reset and set based on new parameter this time
    });
    setTimeout(function() {
      $("#a1").fadeOut('fast');
      $("#a11").fadeOut('fast');
     }, 5000);
  
    let current = 0;
    setInterval(function() {
      var divs = $(".roll").hide();
      divs.eq(current).fadeIn("normal");
      if (current < divs.length - 1)
        current++;
      else
        current = 0;
    }, 5000);
  
    // let current1 = 0;
    // setInterval(function() {
    //   var divs1 = $(".roll1").hide();
    //   divs1.eq(current1).fadeIn("normal");
    //   if (current1 < divs1.length - 1)
    //     current1++;
    //   else 
    //     current1 = 0;
    // }, 5000);
      
}
      
      initialiseState(evId){
      this.spinnerService.show();
        this.apiService.eventGal(evId).subscribe(
          data => {
            console.log(data);
            this.eventlst=data;
           
            this.spinnerService.hide();
          },
          error=>{
            console.log(error);   
            this.spinnerService.hide();
          });
        }

  goToAEvent(evid){
    this.router.navigate(['event'],{ queryParams: {geid: evid}});
  }

}
