import { Component, OnInit } from '@angular/core';
import {Router,ActivatedRoute,NavigationEnd} from "@angular/router";
import {AppService} from "../app.service";
import { DeviceDetectorService } from 'ngx-device-detector';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  navbarOpen = false;
  nvlst:any=[];
  deviceInfo:any=null;
  
  constructor(private router : Router,public activeRoute: ActivatedRoute, private ApiService : AppService, private deviceService: DeviceDetectorService) {

    this.deviceInfo = this.deviceService.getDeviceInfo();
    console.log(this.deviceInfo);
   }

  ngOnInit() {
    this.ApiService.navList().subscribe(
      data => {
        console.log(data);
        this.nvlst=data;
        
       },
      error=>{
        console.log(error);   
      });
  }
  
  toggleNavbar() {
    console.log(this.navbarOpen);
    this.navbarOpen = !this.navbarOpen;
  }

  goToSelected(e, cid, thekey, cName){
    e.preventDefault()
    this.toggleNavbar();
    console.log(thekey);
    if(thekey=='golpo'){
      this.router.navigate(['galpo'],{ queryParams: {sectionid: cid, secName:cName}});
    }else 
    if(thekey=='photo'){
      this.router.navigate(['photos'],{ queryParams: {sectionid: cid, secName:cName}});
    }else
    if(thekey=='video'){
      this.router.navigate(['video'],{ queryParams: {sectionid: cid, secName:cName}});
    }else
    if(thekey=='events'){
      this.router.navigate(['events'],{ queryParams: {sectionid: cid, secName:cName}});
    }else
    if(thekey=='club'){
      this.router.navigate(['club'],{ queryParams: {sectionid: cid, secName:cName}});
    }else
    if(thekey=='kanekane'){
      this.router.navigate(['kanekane'],{ queryParams: {sectionid: cid, secName:cName}});
    }else{
      this.router.navigate(['section'],{ queryParams: {sectionid: cid, secName:cName}});
    }
    if(thekey=='digitalworkshop'){
      this.router.navigate(['digital'],{ queryParams: {sectionid: cid, secName:cName}});
    }
  } 

  goToSelectedDigital(e, cid, thekey, cName){
    e.preventDefault()
    this.toggleNavbar();
    console.log(thekey);
    this.router.navigate(['digital'],{ queryParams: {sectionid: cid, secName:cName}});
  }

  

  // goToSection(){
  //   console.log(this.navbarOpen);
  //   // this.router.navigate(['section'],{ queryParams: {status: 'false'}});
  //   this.router.navigate(['section']);
  // }

  // goToGolpo(){
  //   this.router.navigate(['galpo']);
  // }

  // goToPgal(){
  //   this.router.navigate(['photos']);
  // }

  // goToVid(){
  //   this.router.navigate(['video']);
  // }
  // goToAllEvents(){
  //   this.router.navigate(['events']);
  // }
}

