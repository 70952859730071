import { Component, OnInit } from '@angular/core';
import {Validators, FormBuilder, FormGroup, FormControl  } from '@angular/forms';
import { EventsService } from 'angular4-events';
import {Router,ActivatedRoute} from "@angular/router";
import {AppService} from "../app.service";
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-login-register',
  templateUrl: './login-register.component.html',
  styleUrls: ['./login-register.component.css']
})
export class LoginRegisterComponent implements OnInit {
  public signUpForm : FormGroup;
  userDetail:any={name:'',email:'',password:'',rpassowrd:''};
  user:any={};
  loginuser:any={};
  linkdigital:any=0;
  linkid:any=0;
  eventid:any=0;
  successmsg:any='';
  loginmsg:any='';
  sucss:any=0;
  date:any;
  fp:boolean=false;
  public loginForm : FormGroup;
  public fpForm : FormGroup;

  loginDetail:any={email:"",password:""};

  constructor(public activeRoute: ActivatedRoute,private datePipe: DatePipe,private apiService: AppService,private router : Router,private formBuilder: FormBuilder,private events: EventsService) { }

  ngOnInit() {
    this.date = this.datePipe.transform( new Date(), 'yyyy-MM-dd');

    this.fp = false;
    this.loginForm = this.formBuilder.group({
		  /*mobile: ['',Validators.compose([Validators.minLength(10),Validators.maxLength(10), Validators.required])],*/
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
		  password: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(3)]))
    });
    
    this.fpForm = this.formBuilder.group({
		  /*mobile: ['',Validators.compose([Validators.minLength(10),Validators.maxLength(10), Validators.required])],*/
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ]))
		});

    this.signUpForm = this.formBuilder.group({
      /*mobile: ['',Validators.compose([Validators.minLength(10),Validators.maxLength(10), Validators.required])],*/
      name: new FormControl('', Validators.required),
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      password: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(3)])),
      rpassword: new FormControl('', Validators.required)
    }, {validator: this.checkPasswords});
    
    this.activeRoute.queryParams.subscribe(params => {
      this.linkdigital = params['linkdigital'];
      this.linkid = params['linkid'];
      this.eventid = params['eventid'];
		});
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
  let pass = group.get('password').value;
  let confirmPass = group.get('rpassword').value;

  return pass === confirmPass ? null : { notSame: true }     
}

  signup() {
	  console.log(this.signUpForm);
	  if(!this.signUpForm.valid){
		return;
	  } 
	  this.apiService.signUp(this.userDetail.name,this.userDetail.email,this.userDetail.password).subscribe(
	   data => {
		   console.log(data);
		   this.user=data;
		   if(this.user.userId){
        //alert('Registered');
        this.sucss=1;
        this.successmsg="Successfully registered! Please Login";
        this.login(this.userDetail.email,this.userDetail.password); 
		   }else{        
         this.successmsg="Something is wrong! Please try again.";
		   }
	   },
	   error=>{
		   console.log(error);   
	   }
     );
    }


    signIn(){
    console.log(this.loginForm);
	  if(!this.loginForm.valid){
		return;
    } 
    this.login(this.loginDetail.email,this.loginDetail.password);
    }

    login(uid,pass){
      this.apiService.signIn(uid,pass).subscribe(
        data => {
          console.log(data);
          this.loginuser=data;
          //localStorage.setItem("user",JSON.stringify(this.user));
          if(this.loginuser.state){
           localStorage.setItem("user",JSON.stringify(this.loginuser));
           //alert('Registered');
           this.sucss=1;
           this.events.publish('user:loggedin', this.loginuser);
           console.log(this.linkdigital);
           console.log(this.linkid);
           //this.successmsg="Successfully registered!";
           // this.router.navigate(['user'],{ queryParams: {status: 'false'}});
           if(this.linkdigital ==1){
             this.evtRegister(this.eventid);
             this.router.navigate(['digitalinner'],{ queryParams: {link: this.linkid}});
           } else  if(this.linkdigital ==2){
             this.evtRegister(this.eventid);
             this.router.navigate(['digital'],{ queryParams: {link: this.linkid}});
           } else {
             this.router.navigate(['home'], { replaceUrl: true });
           }
          }else{
            //alert(this.user.message);
            
           this.loginmsg="Something is wrong! Please try again.";
          }
          //this.users = data
        },
        error=>{
          console.log(error);   
        }
      );
    }
    evtRegister(eventid){
          console.log(eventid);
          this.apiService.eventregistration(this.loginuser.uid,eventid,this.date).subscribe(
            data => {
              console.log(data);
              //this.eventdetails();
              //this.modalshow = 'true';
            },
            error=>{
              console.log(error);   
            });
    }

    frgtpass(){
      this.fp = false;
    }
    
    forgetPassword(fpForm){
      console.log(fpForm.email);

      this.apiService.forgetPass(fpForm.email).subscribe(
        data => {
          console.log(data);
          if(data = 'true'){
           this.router.navigate(['loginreg']);
          }
        }, 
        error=>{ 
          console.log(error);     
        }
       );
    }

}
