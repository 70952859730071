import { Component, OnInit } from '@angular/core';
import {AppService} from "../app.service";
import {Router,ActivatedRoute,NavigationEnd} from "@angular/router";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { EmbedVideoService } from 'ngx-embed-video';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css']
})
export class EventComponent implements OnInit {
  cstart:number=0;
  cend:number=10;
  secId:any;
  sName:any;
  evall:any=[];
  galitm:any=[];
  moredata:any=[];
  galtop:any=[];
  vidtop:any=[];
  galbot:any=[];
  youvid:any;
  viditm:any=[];
  vidbot:any=[];
  vdoavail:any;
  vdolst:any=[];
  selectedItem:any;
  selectedVItem:any;
  loadmoretxt:any="LOAD MORE";
  baseImgPath ='https://api.sananda.in';
  
  constructor(private apiService: AppService,private embedService: EmbedVideoService, 
    private _sanitizer: DomSanitizer, private router : Router,public activeRoute: ActivatedRoute, private spinnerService: Ng4LoadingSpinnerService) { }

  ngOnInit() {
    this.activeRoute.queryParams.subscribe(params => {
      this.secId = params['geid'];
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      //location.reload();
      this.spinnerService.show();
      this.initialiseState(this.secId); // reset and set based on new parameter this time
  });

  setTimeout(function() {
    $("#a1").fadeOut('fast');
    $("#a11").fadeOut('fast');
   }, 5000);

  let current = 0;
  setInterval(function() {
    var divs = $(".roll").hide();
    divs.eq(current).fadeIn("normal");
    if (current < divs.length - 1)
      current++;
    else
      current = 0;
  }, 5000);

  // let current1 = 0;
  // setInterval(function() {
  //   var divs1 = $(".roll1").hide();
  //   divs1.eq(current1).fadeIn("normal");
  //   if (current1 < divs1.length - 1)
  //     current1++;
  //   else 
  //     current1 = 0;
  // }, 5000);
  }

  initialiseState(secid){
    this.apiService.eventDetails(secid).subscribe(
    data => {
        console.log(data);
        this.evall=data;
        this.galitm = this.evall.galleryItem;
        console.log(this.galitm.length);
        for(let x=0; x< this.galitm.length; x++){
          if ((x % 2) == 0){
          this.galtop.push(this.galitm[x]);
          }else{
            this.galbot.push(this.galitm[x]);
          }
          
        }
        console.log(this.galtop);
        this.selectedItem=this.evall.galleryItem[0].image;

        ////////////////// video satrt /////////////////////
        this.viditm = this.evall.videoItem;
        this.vdoavail=this.viditm.length;
        console.log(this.viditm.length);
        for(let y=0; y< this.viditm.length; y++){
          if ((y % 2) == 0){
          this.vidtop.push(this.viditm[y]);
          }else{
            this.vidbot.push(this.viditm[y]);
          }
          
        }

        if(this.vdoavail){
        this.selectedVItem=this.evall.videoItem[0].videoId;

        
       
        this.youvid=this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/'+this.selectedVItem+'?rel=0');
        }

        //////////////// end vdo //////////////

        console.log(this.evall);
        this.spinnerService.hide();
        //this.carimage=this.baseImgPath+'/image/journal/article?img_id='+this.str4.imageId;      
    },
    error=>{
      console.log(error);   
      this.spinnerService.hide();
    });
  }

  openBig(cnt){
    console.log(cnt);
    this.selectedItem = cnt;
    //this.mainPhoto=cnt;
  }

  openBigVid(cnt){
    console.log(cnt);
    //this.shouldShow = !this.shouldShow;
    this.selectedVItem = cnt;   
    this.youvid=this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/'+this.selectedVItem+'?rel=0');
  }

}
