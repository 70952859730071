import { Component, OnInit } from '@angular/core';
import {Validators, FormBuilder, FormGroup } from '@angular/forms';
import {Router} from "@angular/router";
import {AppService} from "../app.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

public loginForm : FormGroup;
userDetail:any={email:"1",password:"1"};
user:any={};

  constructor(private apiService: AppService,private router : Router,private formBuilder: FormBuilder) { }

  ngOnInit() {
	  this.loginForm = this.formBuilder.group({
		  /*mobile: ['',Validators.compose([Validators.minLength(10),Validators.maxLength(10), Validators.required])],*/
		  email: ['', Validators.email],
		  password: ['', Validators.compose([Validators.required,Validators.minLength(3)])]
		});

		
  }
  
  
  
  login() {
	  console.log(this.loginForm);
	  if(!this.loginForm.valid){
		return;
	  } 
	  this.apiService.login(this.userDetail.email,this.userDetail.password).subscribe(
	   data => {
		   console.log(data);
		   this.user=data;
		   localStorage.setItem("user",JSON.stringify(this.user));
		
		   if(this.user.result==true){
			   this.router.navigate(['user'],{ queryParams: {status: 'false'}});
		   }else{
			   alert(this.user.message);
		   }
		   //this.users = data
	   },
	   error=>{
		   console.log(error);   
	   }
	   );
	  
	  
	  
	  
	  
	  
	/*if(this.userDetail.email == '1' && this.userDetail.password == '1') {
		this.router.navigate(['user'],{ queryParams: {status: 'false'}});
	}else {
		alert("Invalid credentials.");
	}*/
	}
}
