import { Component, OnInit } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import {Validators, FormBuilder, FormGroup, FormControl  } from '@angular/forms';
import {AppService} from "../app.service";
import {Router,ActivatedRoute} from "@angular/router";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-photos',
  templateUrl: './photos.component.html',
  animations: [
    trigger(
      'enterAnimation', [
        transition(':leave', [
          style({transform: 'translateY(0)', opacity: 1}),
          animate('300ms', style({transform: 'translateY(100%)', opacity: 0}))
          
        ]),
        transition(':enter', [
          style({transform: 'translateY(100%)', opacity: 0}),
          animate('300ms', style({transform: 'translateY(0)', opacity: 1}))
        ])
      ]
    )
  ],
  styleUrls: ['./photos.component.css']
})
export class PhotosComponent implements OnInit {
  showfrmdiv=false;
  photolst:any=[];
  secId:any;
  sName:any;
  cstart:number=0;
  cend:number=9;
  moredata:any=[];
  loadmoretxt:any="LOAD MORE";
  baseImgPath ='https://api.sananda.in';

  uid:number;
  user:any={};
  getglp:any;
  successmsg:any='';
  loginmsg:any='';
  fullname:string;
  sucss:any=0;
  public contestForm : FormGroup;
  conDetail:any={caption:""};

  afuConfig = {
    multiple: false,
    formatsAllowed: ".jpg,.png",
    maxSize: "5",
    uploadAPI:  {
      url:"http://api.sananda.in/api/jsonws/dlapp/add-file-entry/repository-id/20142/folder-id/67671/source-file-name/Test/mime-type/image%2Fjpg/title/Title/description/desc/change-log/1/file",
      headers: {
    //  "Content-Type" : "multipart/form-data",
     "Authorization" : 'Basic dGVzdEBsaWZlcmF5LmNvbTp0ZXN0'
      }
    },
   // theme: "dragNDrop",
    hideProgressBar: false,
    hideResetBtn: true,
    hideSelectBtn: false,
    replaceTexts: {
      selectFileBtn: 'Select Files',
      resetBtn: 'Reset',
      uploadBtn: 'Upload',
      dragNDropBox: 'Drag N Drop',
      attachPinBtn: 'Attach Files...',
      afterUploadMsg_success: 'Successfully Uploaded !',
      afterUploadMsg_error: 'Upload Failed !'
    }
};

  constructor(private apiService: AppService, private router : Router, private formBuilder: FormBuilder, public activeRoute: ActivatedRoute, private spinnerService: Ng4LoadingSpinnerService) { }

 

    ngOnInit() {

      this.contestForm = this.formBuilder.group({
        /*mobile: ['',Validators.compose([Validators.minLength(10),Validators.maxLength(10), Validators.required])],*/
        
        // cimage: new FormControl('', Validators.compose([
        //   Validators.required
        // ])),
        caption: new FormControl('', Validators.compose([
          Validators.required
        ]))
      });


      this.activeRoute.queryParams.subscribe(params => {
        this.secId = params['sectionid'];
        this.sName = params['secName'];
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        //location.reload();
        this.initialiseState(this.secId); // reset and set based on new parameter this time
    });
    
    setTimeout(function() {
      $("#a1").fadeOut('fast');
      $("#a11").fadeOut('fast');
     }, 5000);

    let current = 0;
    setInterval(function() {
      var divs = $(".roll").hide();
      divs.eq(current).fadeIn("normal");
      if (current < divs.length - 1)
        current++;
      else
        current = 0;
    }, 5000);

    let current1 = 0;
    setInterval(function() {
      var divs1 = $(".roll1").hide();
      divs1.eq(current1).fadeIn("normal");
      if (current1 < divs1.length - 1)
        current1++;
      else 
        current1 = 0;
    }, 5000);
    
  }

  
  initialiseState(secid){
    this.spinnerService.show();
    this.apiService.photoMoments(secid, this.cstart, this.cend).subscribe(
      data => {
        console.log(data);
        this.photolst=data;
        //this.image1=this.baseImgPath+'/image/journal/article?img_id='+this.str1.imageId;      
        this.spinnerService.hide();
      },
      error=>{
        console.log(error);   
        this.spinnerService.hide();
      });
  }

  closeFrm(){
    this.showfrmdiv=false;
  }
  openFrm(){
    this.showfrmdiv=true;
  }

  openGal(galid, galnm){
    this.router.navigate(['photo_gal'],{ queryParams: {gid: galid, gnm: galnm}});
  }

  secLoadMore(secid, cend){
    //console.log(this.counter + 'dat size'+this.msonry.length);

  

    this.cstart=cend + 1;
    this.cend=this.cstart + 8;

    this.apiService.photoMoments(secid, this.cstart, this.cend).subscribe(
      data => {
        console.log(data);
        this.moredata=data;
        //this.image1=this.baseImgPath+'/image/journal/article?img_id='+this.str1.imageId;      
        this.photolst = this.photolst.concat(this.moredata);

         if(this.moredata.length==0){
          this.loadmoretxt="NO MORE PHOTOS";
         }

      },
      error=>{
        console.log(error);   
        this.spinnerService.hide();
      });
  }

  addContest(){
    if(!this.contestForm.valid){
      return;
      } 
      this.user=JSON.parse(localStorage.getItem("user"));
    console.log(this.user);
    if(this.user){

      this.fullname=this.user.name;
      this.uid=this.user.uid;

     
           this.sucss=1;
           this.successmsg="Your photo has been successfully submitted!";
         
      // this.apiService.sendKane(encodeURIComponent(this.conDetail.caption),this.fullname,this.uid).subscribe(
      //  data => {
      //    console.log(data);
      //    this.getglp=data;
      //    if(this.getglp.qid){
      //     this.sucss=1;
      //     this.successmsg="Your question has been successfully submitted!";
      //    }else{
           
      //      this.successmsg="Something is wrong! Please try again.";
      //    }
      //  },
      //  error=>{
      //    console.log(error);   
      //  }
      //  );
  }
}

}
