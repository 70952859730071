import { Component, OnInit, ViewChild } from '@angular/core';
import {OwlCarousel} from 'ngx-owl-carousel';
import { EmbedVideoService } from 'ngx-embed-video';
import { DomSanitizer } from '@angular/platform-browser';
import {AppService} from "../app.service";
import {Router, ActivatedRoute} from "@angular/router";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit {
  @ViewChild('owlElement') owlElement: OwlCarousel
  youvid:any;
  selectedItem:any;
  vtag:any=[];
  author:string;
  secId:any;
  sName:any;
  title:string;
  dates:any;
  vdolst:any=[];
  constructor(
    private embedService: EmbedVideoService, 
    private _sanitizer: DomSanitizer, 
    private apiService: AppService, 
    public activeRoute: ActivatedRoute, 
    private router : Router, 
    private spinnerService: Ng4LoadingSpinnerService) { }

  ngOnInit() {

   // this.youvid=this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/'+this.selectedItem+'?rel=0')

    this.activeRoute.queryParams.subscribe(params => {
      this.secId = params['sectionid'];
      this.sName = params['secName'];
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      //location.reload();
      this.initialiseState(this.secId); // reset and set based on new parameter this time
  });
    
  setTimeout(function() {
    $("#a1").fadeOut('fast');
    $("#a11").fadeOut('fast');
   }, 5000);

  let current = 0;
  setInterval(function() {
    var divs = $(".roll").hide();
    divs.eq(current).fadeIn("normal");
    if (current < divs.length - 1)
      current++;
    else
      current = 0;
  }, 5000);

  // let current1 = 0;
  // setInterval(function() {
  //   var divs1 = $(".roll1").hide();
  //   divs1.eq(current1).fadeIn("normal");
  //   if (current1 < divs1.length - 1)
  //     current1++;
  //   else 
  //     current1 = 0;
  // }, 5000);
  
    
  }

  initialiseState(secId){
    this.spinnerService.show();
    this.apiService.vdoGal(secId).subscribe(
      data => {
        console.log(data);
        this.vdolst=data;
        

        this.vtag=this.vdolst.tags;
        this.selectedItem = this.vdolst[0].videoId;
        this.youvid=this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/'+this.selectedItem+'?rel=0');
        this.title=this.vdolst[0].title;
        this.author=this.vdolst[0].author;
        this.dates=this.vdolst[0].dateString;
        this.vtag=this.vdolst[0].tags;
                this.spinnerService.hide();
      },
      error=>{
        console.log(error);   
        this.spinnerService.hide();
      });
  }
  
  openBig(cnt){
    console.log(cnt);
    //this.shouldShow = !this.shouldShow;
    this.selectedItem = cnt.videoId;
    this.title=cnt.title;
    this.author=cnt.author;
    this.dates=cnt.dateString;
    this.vtag=cnt.tags;
    this.youvid=this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/'+this.selectedItem+'?rel=0');
  }
}
