import { Component, OnInit } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import {Validators, FormBuilder, FormGroup, FormControl  } from '@angular/forms';
import {AppService} from "../app.service";
import {Router,ActivatedRoute,NavigationEnd} from "@angular/router";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-kane-kane',
  templateUrl: './kane-kane.component.html',
  styleUrls: ['./kane-kane.component.css'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':leave', [
          style({transform: 'translateY(0)', opacity: 1}),
          animate('300ms', style({transform: 'translateY(100%)', opacity: 0}))
          
        ]),
        transition(':enter', [
          style({transform: 'translateY(100%)', opacity: 0}),
          animate('300ms', style({transform: 'translateY(0)', opacity: 1}))
        ])
      ]
    )
  ],
})
export class KaneKaneComponent implements OnInit {
  showfrmdiv=false;
  cstart:number=0;
  cend:number=5;
  secId:any;
  sName:any;
  current: number = 0;
  selectedIndex=0;
  kanelist:any=[];
  loggedin:any=0;
  user:any={};
  kanekane:any={};
  uid:number;
  moredata:any=[];
  loadmoretxt:any="LOAD MORE";
  baseImgPath ='https://api.sananda.in';
  successmsg:any='';
  loginmsg:any='';
  fullname:string;
  sucss:any=0;
  public kaneKaneForm : FormGroup;
  kaneDetail:any={kanedetails:""};

  constructor(private apiService: AppService,private router : Router,private formBuilder: FormBuilder,public activeRoute: ActivatedRoute, private spinnerService: Ng4LoadingSpinnerService) { }

  ngOnInit() {

    this.kaneKaneForm = this.formBuilder.group({
		  /*mobile: ['',Validators.compose([Validators.minLength(10),Validators.maxLength(10), Validators.required])],*/
      kanedetails: new FormControl('', Validators.compose([
        Validators.required
      ]))
		});

    this.activeRoute.queryParams.subscribe(params => {
    //  this.secId = params['sectionid'];
      this.sName = params['secName'];
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      //location.reload();
      this.spinnerService.show();

      this.user=JSON.parse(localStorage.getItem("user"));
    console.log(this.user);
    if(this.user){
      this.loggedin=1;
      
    }
      this.initialiseState(); // reset and set based on new parameter this time
  });

  }

  
  closeFrm(){
    this.showfrmdiv=false;
  }
  openFrm(){
    this.showfrmdiv=true;
  }

  initialiseState(){
    this.apiService.kanekane(this.cstart, this.cend).subscribe(
    data => {
        console.log(data);
        this.kanelist=data;
        this.spinnerService.hide();
        //this.carimage=this.baseImgPath+'/image/journal/article?img_id='+this.str4.imageId;      
    },
    error=>{
      console.log(error);   
      this.spinnerService.hide();
    });
  }

  secLoadMore(cstart, cend){

    this.cstart=cstart + 6;
    this.cend=cend + 5;

    this.apiService.kanekane(this.cstart, this.cend).subscribe(
      data => {
          console.log(data);
          this.moredata=data;
          this.kanelist = this.kanelist.concat(this.moredata);

          if(this.moredata.length==0){
           this.loadmoretxt="NO MORE STORIES";
          }
          //this.carimage=this.baseImgPath+'/image/journal/article?img_id='+this.str4.imageId;      
      },
      error=>{
        console.log(error);   
        this.spinnerService.hide();
      });
  }

  toggleSection(index){
    console.log(index);
    this.selectedIndex=index;
  }

  addKane(){
    if(!this.kaneKaneForm.valid){
      return;
      } 
      this.user=JSON.parse(localStorage.getItem("user"));
    console.log(this.user);
    if(this.user){

      this.fullname=this.user.name;
      this.uid=this.user.uid;
           
   
      this.apiService.sendKane(encodeURIComponent(this.kaneDetail.kanedetails),this.fullname,this.uid).subscribe(
       data => {
         console.log(data);
         this.kanekane=data;
         if(this.kanekane.qid){
          this.sucss=1;
          this.successmsg="Your question has been successfully submitted!";
         }else{
           
           this.successmsg="Something is wrong! Please try again.";
         }
       },
       error=>{
         console.log(error);   
       }
       );
  }
}

}
