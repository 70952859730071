import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {UserComponent} from './user/user.component';
import {LoginComponent} from './login/login.component';
import { HomeComponent } from './home/home.component';
import { SectionComponent } from './section/section.component';
import { StorypageComponent } from './storypage/storypage.component';
import { GalpoComponent } from './galpo/galpo.component';
import { PhotoGalComponent } from './photo-gal/photo-gal.component';
import { VideoComponent } from './video/video.component';
import { EventLandingComponent } from './event-landing/event-landing.component';
import { EventComponent } from './event/event.component';
import { PhotosComponent } from './photos/photos.component';
import { SearchComponent } from './search/search.component';
import { KaneKaneComponent } from './kane-kane/kane-kane.component';
import { LoginRegisterComponent } from './login-register/login-register.component';
import { StaticPageComponent } from './static-page/static-page.component';
import { ClubPageComponent } from './club-page/club-page.component';
import { DigitalworkshopComponent } from './digitalworkshop/digitalworkshop.component';
import { DigitalworkshopinnerComponent } from './digitalworkshopinner/digitalworkshopinner.component';
import { DigitalvideoComponent } from './digitalvideo/digitalvideo.component';
import { ContentuserComponent } from './contentuser/contentuser.component';

const routes: Routes = [
{ path: 'user', component: UserComponent },
{ path: 'login', component: LoginComponent },
{ path: 'section', component: SectionComponent },
{ path: 'story', component: StorypageComponent },
{ path: 'details/:cat/:title/:link', component: StorypageComponent },
{ path: 'galpo', component: GalpoComponent },
{ path: 'photos', component: PhotosComponent },
{ path: 'photo_gal', component: PhotoGalComponent },
{ path: 'video', component: VideoComponent },
{ path: 'events', component: EventLandingComponent }, 
{ path: 'event', component: EventComponent },
{ path: 'search', component: SearchComponent },
{ path: 'kanekane', component: KaneKaneComponent },
{ path: 'loginreg', component: LoginRegisterComponent },
{ path: 'static', component: StaticPageComponent },
{ path: 'club', component: ClubPageComponent },
{ path : 'home', component : HomeComponent},
{ path: 'digital', component: DigitalworkshopComponent },
{ path : 'digitalinner', component : DigitalworkshopinnerComponent},
{ path : 'digitalvideo', component : DigitalvideoComponent},
{ path : '', component : HomeComponent},
{ path : 'events/:link', component : ContentuserComponent}



];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
