import { Component, OnInit } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import {AppService} from "../app.service";
import {Router,ActivatedRoute,NavigationEnd} from "@angular/router";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  cstart:number=0;
  cend:number=10;
  key:any;
  sName:any;
  resultAvail:any;
  sresult:any=[];
  moredata:any=[];
  loadmoretxt:any="LOAD MORE";
  baseImgPath ='https://api.sananda.in';
  constructor(private apiService: AppService,private router : Router,public activeRoute: ActivatedRoute, private spinnerService: Ng4LoadingSpinnerService) { }

  ngOnInit() {

    this.activeRoute.queryParams.subscribe(params => {
      this.key = params['key'];
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      //location.reload();
      this.spinnerService.show();
      this.initialiseState(this.key); // reset and set based on new parameter this time
  });

     
  }

  initialiseState(key){
    this.apiService.searchResult(key, this.cstart, this.cend).subscribe(
    data => {
        console.log(data);
        this.sresult=data;
        this.resultAvail=this.sresult.length;
        if(this.resultAvail==0){
          this.loadmoretxt="NO DATA AVAILABLE";
        }
        this.spinnerService.hide();
        //this.carimage=this.baseImgPath+'/image/journal/article?img_id='+this.str4.imageId;      
    },
    error=>{
      console.log(error);   
      this.spinnerService.hide();
    }); 
  }

  srchLoadMore(cstart, cend){
    //console.log(this.counter + 'dat size'+this.msonry.length);

  

    this.cstart=cend + 1;
    this.cend=this.cstart + 8;

    this.apiService.searchResult(this.key, this.cstart, this.cend).subscribe(
      data => {
        this.moredata=data;
         //console.log(this.moredata);
         this.sresult = this.sresult.concat(this.moredata);

         if(this.moredata.length==0){
          this.loadmoretxt="NO MORE STORIES";
         }

         // console.log(this.msonry);
          
          //this.carimage=this.baseImgPath+'/image/journal/article?img_id='+this.str4.imageId;      
      },
      error=>{
        console.log(error);   
        
      });
  }

  goToStory(linkid){
    // this.router.navigate(['section'],{ queryParams: {status: 'false'}});
    this.router.navigate(['story'],{ queryParams: {link: linkid}});
  }

}
