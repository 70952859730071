import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { EmbedVideo } from 'ngx-embed-video';
import { NgMasonryGridModule } from 'ng-masonry-grid';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { PathLocationStrategy, LocationStrategy } from '@angular/common';
import { EventsModule } from 'angular4-events';
import { DatePipe } from '@angular/common';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UserComponent } from './user/user.component';
import { LoginComponent } from './login/login.component';
import { NavbarComponent } from './navbar/navbar.component';
import { HomeComponent } from './home/home.component';
import { OwlModule } from 'ngx-owl-carousel';
import { SectionComponent } from './section/section.component';
import { AngularFileUploaderModule } from "angular-file-uploader";
import { ShareButtonsModule } from 'ngx-sharebuttons';
import { MetaGuard } from 'ng2-meta';
import { MetaModule } from 'ng2-meta';
import { DeviceDetectorModule } from 'ngx-device-detector';

import { CollapseModule, BsDropdownModule } from 'ngx-bootstrap';
import { StorypageComponent } from './storypage/storypage.component';
import { GalpoComponent } from './galpo/galpo.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PhotoGalComponent } from './photo-gal/photo-gal.component';
import { VideoComponent } from './video/video.component';
import { EventLandingComponent } from './event-landing/event-landing.component';
import { EventComponent } from './event/event.component';
import { PhotosComponent } from './photos/photos.component';
import { SearchComponent } from './search/search.component'; 
import { KaneKaneComponent } from './kane-kane/kane-kane.component';
import { LoginRegisterComponent } from './login-register/login-register.component';
import { StaticPageComponent } from './static-page/static-page.component';
import { ClubPageComponent } from './club-page/club-page.component';
import { DigitalworkshopComponent } from './digitalworkshop/digitalworkshop.component';
import { DigitalworkshopinnerComponent } from './digitalworkshopinner/digitalworkshopinner.component';
import { DigitalvideoComponent } from './digitalvideo/digitalvideo.component';
import { ContentuserComponent } from './contentuser/contentuser.component';


@NgModule({ 
  declarations: [
    AppComponent,
    UserComponent,
    LoginComponent,
    NavbarComponent,
    HomeComponent,
    SectionComponent,
    StorypageComponent,
    GalpoComponent,
    PhotoGalComponent,
    VideoComponent,
    EventLandingComponent,
    EventComponent,
    PhotosComponent,
    SearchComponent,
    KaneKaneComponent,
    LoginRegisterComponent,
    StaticPageComponent,
    ClubPageComponent,
    DigitalworkshopComponent,
    DigitalworkshopinnerComponent,
    DigitalvideoComponent,
    ContentuserComponent
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
  FormsModule,
  BrowserAnimationsModule,
  ReactiveFormsModule,
  MetaModule.forRoot(),
  OwlModule,
  ShareButtonsModule.forRoot(),
  AngularFileUploaderModule,
  EventsModule.forRoot(),
  Ng4LoadingSpinnerModule.forRoot(),
  DeviceDetectorModule.forRoot(),
  NgMasonryGridModule,
  CollapseModule.forRoot(), BsDropdownModule.forRoot(),
  HttpClientModule,
  EmbedVideo.forRoot()
  ],
  providers: [DatePipe,{provide: LocationStrategy, useClass: PathLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
