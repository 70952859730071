import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {
 //baseURL="https://dummy.sananda.in/api/jsonws/contentservice.content";
 //baseURL1="https://dummy.sananda.in/api/jsonws";
 baseURL="https://api.sananda.in/api/jsonws/contentservice.content";
 baseURL1="https://api.sananda.in/api/jsonws";
 
  constructor(private http:HttpClient) { } 
  createAuthorizationHeader(headers: HttpHeaders) {
    headers.append('Authorization', 'Basic dGVzdEBsaWZlcmF5LmNvbTp0ZXN0'); 
  }

  x=[];
  public getUsers() {
	let fakeUsers = [{id: 1, firstName: 'Dhiraj', lastName: 'Ray', email: 'soumennew@gmail.com'},
	{id: 2, firstName: 'Tom', lastName: 'Jac', email: 'Tom@gmail.com'},
	{id: 3, firstName: 'Hary', lastName: 'Pan', email: 'hary@gmail.com'},
	{id: 4, firstName: 'praks', lastName: 'pb', email: 'praks@gmail.com'},
	];
	return of(fakeUsers);
  }
  
  signUp(name, email, pass){
    return this.http.get(this.baseURL+'/register/name/'+name+'/password1/'+pass+'/password2/'+pass+'/email/'+email);
  }
  
  signIn(email, pass){
    return this.http.get('https://api.sananda.in/web/guest/apps?p_p_id=AuthenticateUser_INSTANCE_OAuVOq3l39ad&p_p_lifecycle=2&p_p_state=normal&login='+email+'&password='+pass);
  }
  
  sendKane(qstn, name, uid){
    let headers = new HttpHeaders();
    return this.http.get('https://api.sananda.in/api/jsonws/kanekane.kanekane/add-kane-kane-question/name/'+name+'/uid/'+uid+'/question/'+qstn, {headers: headers});  
  }
  
  login(email,password) {
    let headers = new HttpHeaders();
        return this.http.post(this.baseURL+'/user-login',{email:email,password:password},{headers: headers});
  }

  homeTopStory(posi) {
    let headers = new HttpHeaders();
    return this.http.get(this.baseURL+'/get-content-by-page-position/page/home/position-name/HomePosition'+posi+'/category/0', {headers: headers});
}

secTopStory(posi, secid) {
  let headers = new HttpHeaders();
  return this.http.get(this.baseURL+'/get-content-by-page-position/page/section/position-name/SectionPosition'+posi+'/category/'+secid, {headers: headers});
}
 
homeCaro(){
  let headers = new HttpHeaders();
  return this.http.get(this.baseURL+'/get-home-gallery-list', {headers: headers});
}

latestEdition(){
  let headers = new HttpHeaders();
  return this.http.get(this.baseURL+'/latest-edition', {headers: headers});
}

oldEdition(){
  let headers = new HttpHeaders();
  return this.http.get(this.baseURL+'/get-old-editions', {headers: headers});
}

relCaro(catid, storyid){
  let headers = new HttpHeaders();
  return this.http.get(this.baseURL+'/get-related-stories/category-id/'+catid+'/article-id/'+storyid, {headers: headers});
}
homeMason(strt, end){
  let headers = new HttpHeaders();
  return this.http.get(this.baseURL+'/Home-other-stories/start/'+strt+'/end/'+end, {headers: headers});  
}
moreHomeMason(strt, end){
  let headers = new HttpHeaders();
  return this.http.get(this.baseURL+'/Home-other-stories/start/'+strt+'/end/'+end, {headers: headers});  
}
secMason(catid, strt, end){
  let headers = new HttpHeaders();
  return this.http.get(this.baseURL+'/Section-other-stories/category-id/'+catid+'/start/'+strt+'/end/'+end, {headers: headers});  
}

moreSecMason(catid, strt, end){ 
  let headers = new HttpHeaders();
  return this.http.get(this.baseURL+'/Section-other-stories/category-id/'+catid+'/start/'+strt+'/end/'+end, {headers: headers});  
}

searchResult(key, strt, end){
  let headers = new HttpHeaders();
  return this.http.get(this.baseURL+'/search/keyword/'+key+'/start/'+strt+'/end/'+end, {headers: headers});  
}

kanekane(strt, end){
  let headers = new HttpHeaders();
  return this.http.get(this.baseURL+'/get-kane-kane-list/start/'+strt+'/end/'+end, {headers: headers});  
}

eventDetails(eid){
  let headers = new HttpHeaders();
  return this.http.get(this.baseURL+'/event-item/event-id/'+eid, {headers: headers});  
}

mainStory(linkId){
  let headers = new HttpHeaders();
  return this.http.get(this.baseURL+'/get-story/article-id/'+linkId, {headers: headers});  
}

photoMoments(secId, strt, end){
  let headers = new HttpHeaders();
  return this.http.get(this.baseURL+'/gallerylist/categoryId/'+secId+'/start/'+strt+'/end/'+end, {headers: headers});  
}


photoGal(gid){
  let headers = new HttpHeaders();
  return this.http.get(this.baseURL+'/gallery-item/gallery-id/'+gid, {headers: headers});  
}

eventGal(gid){
  let headers = new HttpHeaders();
  return this.http.get(this.baseURL+'/event-list', {headers: headers});  
}

vdoGal(vid){
  let headers = new HttpHeaders();
  return this.http.get(this.baseURL+'/videolist/categoryId/'+vid, {headers: headers});  
}

navList(){
  let headers = new HttpHeaders();
  return this.http.get(this.baseURL+'/navigation', {headers: headers});  
}

theClub(){
  let headers = new HttpHeaders();
  return this.http.get(this.baseURL+'/club-item', {headers: headers});  
}
  
digitallist(uid){
  let headers = new HttpHeaders();
  return this.http.get(this.baseURL1+'/digitalworkspace.digitalworkspace/event-list/uid/'+uid, {headers: headers});  
}

digitaldetail(loggedin,linkId){
  let headers = new HttpHeaders();
  return this.http.get(this.baseURL1+'/digitalworkspace.digitalworkspace/get-event/uid/'+loggedin+'/event-id/'+linkId, {headers: headers});  
}

eventregistration(uid,eventid,date){
  let headers = new HttpHeaders();
  return this.http.get(this.baseURL1+'/eventusersubscription.eventusersubscription/add-user-event/uid/'+uid+'/event-id/'+eventid+'/registeredon/'+date, {headers: headers});  
}

forgetPass(email) {
  let headers = new HttpHeaders();
  return this.http.get(this.baseURL1+'/usercontent.usercontent/resetpassword/email/'+email, {headers: headers});
}

uploadcontent(formData) {
  let headers = new HttpHeaders();
      return this.http.post('https://usercontestapi.sananda.in/saveForm',formData, {headers: headers});
}

}
   