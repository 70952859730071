import { Component, OnInit } from '@angular/core';
import {AppService} from "../app.service";
import {Router,ActivatedRoute,NavigationEnd} from "@angular/router";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-club-page',
  templateUrl: './club-page.component.html',
  styleUrls: ['./club-page.component.css']
})
export class ClubPageComponent implements OnInit {
  thisWeek:any=1;
  clubAll:any={};
  futureWeek:any=0;
  constructor(private apiService: AppService,private router : Router,public activeRoute: ActivatedRoute, private spinnerService: Ng4LoadingSpinnerService) { 

    this.spinnerService.show();
  }

  ngOnInit() {
    setTimeout(function() {
      $("#a1").fadeOut('fast');
      $("#a11").fadeOut('fast');
     }, 5000);

    let current = 0;
    setInterval(function() {
      var divs = $(".roll").hide();
      divs.eq(current).fadeIn("normal");
      if (current < divs.length - 1)
        current++;
      else
        current = 0;
    }, 5000);

    // let current1 = 0;
    // setInterval(function() {
    //   var divs1 = $(".roll1").hide();
    //   divs1.eq(current1).fadeIn("normal");
    //   if (current1 < divs1.length - 1)
    //     current1++;
    //   else 
    //     current1 = 0;
    // }, 5000);
    this.apiService.theClub().subscribe(
      data => {
        this.clubAll=data;    
        this.spinnerService.hide();    
      },
      error=>{
        console.log(error);   
        
      });


  }
  goThisWeek(){
    this.thisWeek=1;
    this.futureWeek=0;
  }

  goFutureWeek(){
    this.thisWeek=0;
    this.futureWeek=1;
  }


}

