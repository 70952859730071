import { Component, OnInit } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import {Validators, FormBuilder, FormGroup, FormControl  } from '@angular/forms';
import {AppService} from "../app.service";
import {Router,ActivatedRoute,NavigationEnd} from "@angular/router";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';

@Component({
  selector: 'app-galpo',
  templateUrl: './galpo.component.html',
  animations: [
    trigger(
      'enterAnimation', [
        transition(':leave', [
          style({transform: 'translateY(0)', opacity: 1}),
          animate('300ms', style({transform: 'translateY(100%)', opacity: 0}))
          
        ]),
        transition(':enter', [
          style({transform: 'translateY(100%)', opacity: 0}),
          animate('300ms', style({transform: 'translateY(0)', opacity: 1}))
        ])
      ]
    )
  ],
  styleUrls: ['./galpo.component.css']
})
export class GalpoComponent implements OnInit {
  showfrmdiv=false;
  cstart:number=0;
  cend:number=10;
  secId:any;
  sName:any;
  user:any={};
  loggedin:any=0;
  golpo:any=[];
  moredata:any=[];
  uid:number;
  getglp:any;
  fileMeta:any;
  successmsg:any='';
  loginmsg:any='';
  fullname:string;
  sucss:any=0;
  public golpoForm : FormGroup;
  gDetail:any={title:"",byline:"",content:""};
  loadmoretxt:any="LOAD MORE";
  baseImgPath ='https://api.sananda.in';
  constructor(public http: HttpClient,private apiService: AppService,private router : Router,private formBuilder: FormBuilder,public activeRoute: ActivatedRoute, private spinnerService: Ng4LoadingSpinnerService) { }

  ngOnInit() {

    this.golpoForm = this.formBuilder.group({
		  /*mobile: ['',Validators.compose([Validators.minLength(10),Validators.maxLength(10), Validators.required])],*/
      title: new FormControl('', Validators.compose([
        Validators.required
      ])),
      byline: new FormControl('', Validators.compose([
        Validators.required
      ])),
      content: new FormControl('', Validators.compose([
        Validators.required
      ]))
		});

    this.user=JSON.parse(localStorage.getItem("user"));
    console.log(this.user);
    if(this.user){
      this.loggedin=1;
      
    }

    this.activeRoute.queryParams.subscribe(params => {
      this.secId = params['sectionid'];
      this.sName = params['secName'];
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      //location.reload();
      this.spinnerService.show();
      this.initialiseState(this.secId); // reset and set based on new parameter this time
  });

  setTimeout(function() {
    $("#a1").fadeOut('fast');
    $("#a11").fadeOut('fast');
   }, 5000);

  let current = 0;
  setInterval(function() {
    var divs = $(".roll").hide();
    divs.eq(current).fadeIn("normal");
    if (current < divs.length - 1)
      current++;
    else
      current = 0;
  }, 5000);

  // let current1 = 0;
  // setInterval(function() {
  //   var divs1 = $(".roll1").hide();
  //   divs1.eq(current1).fadeIn("normal");
  //   if (current1 < divs1.length - 1)
  //     current1++;
  //   else 
  //     current1 = 0;
  // }, 5000);
  
  }

  initialiseState(secid){
    this.apiService.secMason(secid, this.cstart, this.cend).subscribe(
    data => {
        console.log(data);
        this.golpo=data;
        this.spinnerService.hide();
        //this.carimage=this.baseImgPath+'/image/journal/article?img_id='+this.str4.imageId;      
    },
    error=>{
      console.log(error);   
      this.spinnerService.hide();
    });
  }

  closeFrm(){
    this.showfrmdiv=false;
  }
  openFrm(){
    this.showfrmdiv=true;
  }

  secLoadMore(cstart, cend){
    //console.log(this.counter + 'dat size'+this.msonry.length);

  

    this.cstart=cstart + 10;
    this.cend=cend + 9;

    this.apiService.moreSecMason(this.secId, this.cstart, this.cend).subscribe(
      data => {
        this.moredata=data;
         //console.log(this.moredata);
         this.golpo = this.golpo.concat(this.moredata);

         if(this.moredata.length==0){
          this.loadmoretxt="NO MORE STORIES";
         }

         // console.log(this.msonry);
          
          //this.carimage=this.baseImgPath+'/image/journal/article?img_id='+this.str4.imageId;      
      },
      error=>{
        console.log(error);   
        
      });
  }

  goToStory(linkid){
    // this.router.navigate(['section'],{ queryParams: {status: 'false'}});
    this.router.navigate(['story'],{ queryParams: {link: linkid}});
  }

  addGolpo(){
    if(!this.golpoForm.valid){
      return;
      } 
   this.user=JSON.parse(localStorage.getItem("user"));
    console.log(this.user);
    if(this.user){

      this.fullname=this.user.name;
      this.uid=this.user.uid;
        this.uploadFile();
     
  }
}

public selectFile(event){
  // this.isImageFile=false;
    console.log(event);
  this.fileMeta=event.target.files[0];
  console.log(this.fileMeta);
  
  // this.lastFileMimeType=fileMeta.type; 
  
}

public uploadFile() {
  
  
  
  var d = new Date();
  var n = d.getTime();
  var title=this.gDetail.title;
  // Destination URL baseURL = 'http://api.aaroananda.abp.in';
  //http://api.sananda.in/api/jsonws/dlapp/add-file-entry/repository-id/20142/folder-id/67671/source-file-name/Test/mime-type/image%2Fjpg/title/Title/description/desc/change-log/1/file
  
  //https://api.sananda.in/api/jsonws/usercontent.usercontent/Upload-content/title/Testerew%20Title/description/descewrew/story/wer%20ewr%20ewr%20ewrew%20rew%20rewr%20/file
 
 
 
 const formData = new FormData();
 formData.append('file', this.fileMeta,this.fileMeta.name);
 
 //formData.append('fileName', filename);
 //formData.append('sourceFileName', this.fileMeta.name);
 formData.append('title', title);
 //formData.append('changeLog', 'Test');
 formData.append('description', this.gDetail.byline);
 formData.append('story', this.gDetail.content);
 //formData.append('mimeType', this.fileMeta.type);
 //formData.append('folderId', '67671');
 //formData.append('repositoryId', '20142');
 
 var url = "https://www.sananda.in/api/api/jsonws/usercontent.usercontent/Upload-content";
  
  let headers = new HttpHeaders().set('authorization', 'Basic dGVzdEBsaWZlcmF5LmNvbTp0ZXN0');
this.http.post(url,formData,{ headers: headers })
.subscribe((data:any)=>{
    console.log(data);
    this.sucss=1;
    this.successmsg="Your content has been successfully submitted!";
    //this.presentToast('Image succesful uploaded.');
   // this.reportFormSubmitted=true;
},err => 
{ 
  console.log(err);
  this.successmsg="Something went wrong! Please try again later.";
  //this.presentToast('Error while uploading content.');
  //this.reportFormSubmitted=false;
});
}

}
