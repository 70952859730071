import { Component, OnInit } from '@angular/core';
import {Router,ActivatedRoute,NavigationEnd} from "@angular/router";
import {AppService} from "../app.service";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-static-page',
  templateUrl: './static-page.component.html',
  styleUrls: ['./static-page.component.css']
})
export class StaticPageComponent implements OnInit {
  linkId:any;
  constructor(private router : Router,public activeRoute: ActivatedRoute, private apiService: AppService, private spinnerService: Ng4LoadingSpinnerService) { }

  ngOnInit() {

    this.spinnerService.show();
    this.linkId = this.activeRoute.snapshot.queryParams['link'];    

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });

    // this.apiService.staticPage(this.linkId).subscribe(
    //   data => {
    //     console.log(data);
    //   },
    //   error=>{
    //     console.log(error);   
    //   });

    setTimeout(function() {
      $("#a1").fadeOut('fast');
      $("#a11").fadeOut('fast');
     }, 5000);

    let current = 0;
    setInterval(function() {
      var divs = $(".roll").hide();
      divs.eq(current).fadeIn("normal");
      if (current < divs.length - 1)
        current++;
      else
        current = 0;
    }, 5000);

    // let current1 = 0;
    // setInterval(function() {
    //   var divs1 = $(".roll1").hide();
    //   divs1.eq(current1).fadeIn("normal");
    //   if (current1 < divs1.length - 1)
    //     current1++;
    //   else 
    //     current1 = 0;
    // }, 5000);
  }

}
