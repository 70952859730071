import { Component, OnInit ,ViewChild, ElementRef} from '@angular/core';
import {Router,ActivatedRoute,NavigationEnd} from "@angular/router";
import {AppService} from "../app.service";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { MetaService } from 'ng2-meta';
import { DatePipe } from '@angular/common';
import { trigger, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-digitalworkshopinner',
  templateUrl: './digitalworkshopinner.component.html',
  animations: [
    trigger(
      'enterAnimation', [
        transition(':leave', [
          style({transform: 'translateY(0)', opacity: 1}),
          animate('300ms', style({transform: 'translateY(100%)', opacity: 0}))
          
        ]),
        transition(':enter', [ 
          style({transform: 'translateY(100%)', opacity: 0}),
          animate('300ms', style({transform: 'translateY(0)', opacity: 1}))
        ])
      ]
    )
  ],
  styleUrls: ['./digitalworkshopinner.component.css']
}) 
export class DigitalworkshopinnerComponent implements OnInit { 

  linkId:any; 
  storyDet:any={};
  storyDetmsg:any={};
  baseImgPath ='https://api.sananda.in';
  storyDetImg:any; 
  catid:any;
  strtagsl:any;
  strtags:any=[];
  relatedstr:any=[];
  user:any={};
  loggedin:any=0;
  date:any;
  uid:any=0;
  modalshow='false';
  constructor(private router : Router,private datePipe: DatePipe,public activeRoute: ActivatedRoute, private metaService: MetaService, private apiService: AppService, private spinnerService: Ng4LoadingSpinnerService) {
   }
  ngOnInit() {
    
    this.date = this.datePipe.transform( new Date(), 'yyyy-MM-dd');
    this.metaService.setTitle('Sananda: '+this.storyDet.title);
    this.metaService.setTag('og:image',this.storyDetImg);
    this.spinnerService.show();
    this.linkId = this.activeRoute.snapshot.queryParams['link'];    

    this.user=JSON.parse(localStorage.getItem("user"));
    console.log(this.user);
    if(this.user){
      this.loggedin=1;
      this.uid = this.user.uid;
    } 

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      } 
      window.scrollTo(0, 0) 
  });
      this.spinnerService.show();
      this.eventdetails();
  }

  eventdetails(){
    this.apiService.digitaldetail(this.uid,this.linkId).subscribe(
      data => {
        console.log(data);
        this.storyDetmsg = data;
        this.storyDet=data['event'];
        this.storyDetImg=this.baseImgPath+'/image/journal/article?img_id='+this.storyDet.image;   

        this.metaService.setTitle('Sananda: '+this.storyDet.name);
        this.metaService.setTag('og:title', 'Sananda: '+this.storyDet.name);
        this.metaService.setTag('og:description', this.storyDet.desc);
        this.metaService.setTag('og:image',this.storyDetImg);
        this.spinnerService.hide();
      },
      error=>{
        console.log(error);   
      });
  }

  evtRegister(userid,eventid,date){
    if(this.loggedin == 1){
        console.log(userid);
        console.log(eventid);
        console.log(date);
        this.apiService.eventregistration(userid,eventid,date).subscribe(
          data => {
            console.log(data);
            this.eventdetails();
            this.modalshow = 'true';
          },
          error=>{
            console.log(error);   
          });
    } else {
      this.router.navigate(['loginreg'],{ queryParams: {linkdigital: 1,linkid: this.linkId}});
    }
  }

  evtJoin(){
    this.router.navigate(['digitalvideo'],{ queryParams: {link: this.linkId,uid: this.uid}});
  }

}
