import { Component, OnInit } from '@angular/core';
import {Validators, FormBuilder, FormGroup, FormControl  } from '@angular/forms';
import { EventsService } from 'angular4-events';
import {Router,ActivatedRoute} from "@angular/router";
import {AppService} from "../app.service";
import { DatePipe } from '@angular/common';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({ 
  selector: 'app-contentuser',
  templateUrl: './contentuser.component.html',
  styleUrls: ['./contentuser.component.css'] 
})
export class ContentuserComponent implements OnInit {
  userDetail:any={name:'',phone:'',file:'',video:''};
  public signUpForm : FormGroup;
  successmsg:any='';
  failuremsg:any=''; 
  fp:boolean=false;
  content:any={};
  linkId:string;
  constructor(public activeRoute: ActivatedRoute,
    private datePipe: DatePipe,private apiService: AppService,
    private router : Router,private formBuilder: FormBuilder,
    private events: EventsService,private spinnerService: Ng4LoadingSpinnerService) { }

  ngOnInit() {
    this.linkId = this.activeRoute.snapshot.params.link;
    console.log( this.linkId);
    this.signUpForm = this.formBuilder.group({
      /*mobile: ['',Validators.compose([Validators.minLength(10),Validators.maxLength(10), Validators.required])],*/
      name: new FormControl('', Validators.required),
      phone: ['',Validators.compose([Validators.required,Validators.pattern('[0-9]{10}')])],
      image: new FormControl('', Validators.required),
      video: new FormControl('', Validators.required)
    });
    
  }

    filedataImage:any;
    fileEventImage(e){
      this.filedataImage=e.target.files[0];
      console.log(e);
    }

    filedataVideo:any;
    fileEventVideo(e){
    this.filedataVideo=e.target.files[0];
    console.log(e);
    }

  submitContent() {
    this.successmsg= '';
    this.failuremsg= '';
	  console.log(this.signUpForm);
	  if(!this.signUpForm.valid){
		return;
    } 
    this.spinnerService.show();
    let formdata = new FormData();
    formdata.append("name",this.userDetail.name);
    formdata.append("phone",this.userDetail.phone);
    formdata.append("image",this.filedataImage);
    formdata.append("video",this.filedataVideo);

	  this.apiService.uploadcontent(formdata).subscribe(
	   data => {
      console.log(data);
      this.content=data;
      if(this.content.status){
       this.spinnerService.hide(); 
       this.signUpForm.reset();
       this.successmsg= this.content.message;
       this.failuremsg= '';
      }else{       
        this.spinnerService.hide();   
        this.failuremsg= this.content.message;
        this.successmsg= ''; 
     } 
	   },   
	   error=>{
       console.log(error);  
       this.spinnerService.hide(); 
	   }  
     );
    }



}
