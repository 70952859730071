import { Component, OnInit, ViewChild,ElementRef } from '@angular/core';
import {OwlCarousel} from 'ngx-owl-carousel';
//import * as $ from 'jquery';
import {AppService} from "../app.service";
import {Router} from "@angular/router";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  closeResult: string;
  @ViewChild('owlElement') owlElement: OwlCarousel
  str1:any={};
  image1:any;
  str2:any={};
  image2:any;
  str3:any={};
  msonry:any;
  image3:any;
  str4:any={};
  image4:any;
  str5:any={};
  image5:any;
  str6:any={};
  image6:any;
  str7:any={};
  oldEdi:any=[];
  image7:any;
  baseImgPath ='https://api.sananda.in';
  carstr:any=[];
  cstart:number=0;
  cend:number=9;
  moredata:any=[];
  loadmoretxt:any="LOAD MORE";
  carimage:any;
  constructor(private apiService: AppService, 
    private router : Router, private spinnerService: Ng4LoadingSpinnerService) {
    
   }

  ngOnInit() {
   
    window.scrollTo(0, 0) 
   
    this.spinnerService.show();
    this.apiService.homeTopStory('1').subscribe(
      data => { 
        console.log(data);
        this.str1=data;
        this.image1=this.baseImgPath+'/image/journal/article?img_id='+this.str1.imageId;      
      },
      error=>{
        console.log(error);   
      });
 
      this.apiService.homeCaro().subscribe(
        data => {
            console.log(data);
            this.carstr=data;
            this.spinnerService.hide();
            //this.carimage=this.baseImgPath+'/image/journal/article?img_id='+this.str4.imageId;      
            
        },
        error=>{
          console.log(error);   
          this.spinnerService.hide();
        });  

      this.apiService.homeTopStory('2').subscribe(
        data => {
          console.log(data);
          this.str2=data;
          this.image2=this.baseImgPath+'/image/journal/article?img_id='+this.str2.imageId;      
        },
        error=>{
          console.log(error);   
        });

        this.apiService.homeTopStory('3').subscribe(
          data => {
            console.log(data);
            this.str3=data;
            this.image3=this.baseImgPath+'/image/journal/article?img_id='+this.str3.imageId;      
          },
          error=>{
            console.log(error);   
          });

          this.apiService.homeTopStory('4').subscribe(
            data => {
                console.log(data);
                this.str4=data;
                this.image4=this.baseImgPath+'/image/journal/article?img_id='+this.str4.imageId;      
            },
            error=>{
              console.log(error);   
          });  

          this.apiService.homeTopStory('5').subscribe(
            data => {
                console.log(data);
                this.str5=data;
                this.image5=this.baseImgPath+'/image/journal/article?img_id='+this.str5.imageId;      
            },
            error=>{
              console.log(error);   
          });  

          this.apiService.homeTopStory('6').subscribe(
            data => {
                console.log(data);
                this.str6=data;
                this.image6=this.baseImgPath+'/image/journal/article?img_id='+this.str6.imageId;      
            },
            error=>{
              console.log(error);   
          });  

          this.apiService.homeTopStory('7').subscribe(
            data => {
                console.log(data);
                this.str7=data;
                this.image7=this.baseImgPath+'/image/journal/article?img_id='+this.str7.imageId;      
            },
            error=>{
              console.log(error);   
          });  
            
            this.apiService.homeMason(this.cstart, this.cend).subscribe(
              data => {
                  console.log(data);
                  this.msonry=data;
                 
                  //this.carimage=this.baseImgPath+'/image/journal/article?img_id='+this.str4.imageId;      
              },
              error=>{
                console.log(error);   
                //this.spinnerService.hide();
              }); 

              this.apiService.oldEdition().subscribe(
                data => {
                    console.log(data);
                    this.oldEdi=data;
                    //this.oldImage=this.baseImgPath+'/image/journal/article?img_id='+this.str7.imageId;      
                },
                error=>{
                  console.log(error);   
              });  

              setTimeout(function() {
                $("#a1").fadeOut('fast');
                $("#a11").fadeOut('fast');
               }, 5000);

              let current = 0;
              setInterval(function() {
                var divs = $(".roll").hide();
                divs.eq(current).fadeIn("normal");
                if (current < divs.length - 1)
                  current++;
                else
                  current = 0;
              }, 5000);

              let current1 = 0;
              setInterval(function() {
                var divs1 = $(".roll1").hide();
                divs1.eq(current1).fadeIn("normal");
                if (current1 < divs1.length - 1)
                  current1++;
                else 
                  current1 = 0;
              }, 5000);
              
  }

  closePop(){
    var x = document.getElementById("myModal");
    x.style.display = "none";
      document.getElementById('myModal').className += ' popNone';
      document.getElementById('myModal').classList.remove("show");

  }

//   open(content) {
//     this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
//       this.closeResult = `Closed with: ${result}`;
//     }, (reason) => {
//       this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
//     });
//   }

// private getDismissReason(reason: any): string {
//   if (reason === ModalDismissReasons.ESC) {
//     return 'by pressing ESC';
//   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
//     return 'by clicking on a backdrop';
//   } else {
//     return  `with: ${reason}`;
//   }
// }
  

  handleMasonryLayoutEvents(event){
		console.log(event);
	}

  
  goToSec(secId, secNm){
    this.spinnerService.show();
    this.router.navigate(['section'],{ queryParams: {sectionid: secId, secName: secNm}});
  }

  goToStory(linkid){
    // this.router.navigate(['section'],{ queryParams: {status: 'false'}});
    this.router.navigate(['story'],{ queryParams: {link: linkid}});
  }

  homeLoadMore(cstart, cend){
    //console.log(this.counter + 'dat size'+this.msonry.length);

  

    this.cstart=cend + 1;
    this.cend=this.cstart + 9;

    this.apiService.moreHomeMason(this.cstart, this.cend).subscribe(
      data => {
        this.moredata=data;
         //console.log(this.moredata);
         this.msonry = this.msonry.concat(this.moredata);

         if(this.moredata.length==0){
          this.loadmoretxt="NO MORE STORIES";
         }

         // console.log(this.msonry);
          
          //this.carimage=this.baseImgPath+'/image/journal/article?img_id='+this.str4.imageId;      
      },
      error=>{
        console.log(error);   
        
      });
  }


}
