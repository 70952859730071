import { Component, OnInit } from '@angular/core';
import {Router,ActivatedRoute,NavigationEnd} from "@angular/router";
import {AppService} from "../app.service";
import {OwlCarousel} from 'ngx-owl-carousel';
import * as $ from 'jquery';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.css']
})
export class SectionComponent implements OnInit {
  secId:any;
  sName:any;
  secstr1:any={};
  secimage1:any;
  msonry:any=[];
  secstr2:any={};
  oldEdi:any=[];
  secimage2:any;
  secstr3:any={};
  cstart:number=0;
  cend:number=9;
  moredata:any=[];
  loadmoretxt:any="LOAD MORE";
  secimage3:any;
  baseImgPath ='https://api.sananda.in';
  constructor(private router : Router,public activeRoute: ActivatedRoute, private apiService: AppService, private spinnerService: Ng4LoadingSpinnerService) { }

  ngOnInit() {
    this.activeRoute.queryParams.subscribe(params => {
      this.secId = params['sectionid'];
      this.sName = params['secName'];
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      //location.reload();
      this.spinnerService.show();
      this.initialiseState(this.secId); // reset and set based on new parameter this time


      setTimeout(function() {
        $("#a1").fadeOut('fast');
        $("#a11").fadeOut('fast');
       }, 5000);

      let current = 0;
      setInterval(function() {
        var divs = $(".roll").hide();
        divs.eq(current).fadeIn("normal");
        if (current < divs.length - 1)
          current++;
        else
          current = 0;
      }, 5000);

      let current1 = 0;
      setInterval(function() {
        var divs1 = $(".roll1").hide();
        divs1.eq(current1).fadeIn("normal");
        if (current1 < divs1.length - 1)
          current1++;
        else 
          current1 = 0;
      }, 5000);
  });
   // this.secId = this.activeRoute.snapshot.queryParams['sectionid'];
   // this.sName = this.activeRoute.snapshot.queryParams['secName'];
    //this.sName= secName;

    console.log(this.secId);
    //location.reload();
    
    

    // =============== load on top =================
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });

    
       

  }

  initialiseState(secId){
    this.apiService.secTopStory('1', secId).subscribe(
      data => {
        console.log(data);
        this.secstr1=data;
        this.secimage1=this.baseImgPath+'/image/journal/article?img_id='+this.secstr1.imageId;      
      },
      error=>{
        console.log(error);   
      });

      this.apiService.secTopStory('2', secId).subscribe(
        data => {
          console.log(data);
          this.secstr2=data;
          this.secimage2=this.baseImgPath+'/image/journal/article?img_id='+this.secstr2.imageId;      
        },
        error=>{
          console.log(error);   
        });

        this.apiService.secTopStory('3', secId).subscribe(
          data => {
            console.log(data);
            this.secstr3=data;
            this.secimage3=this.baseImgPath+'/image/journal/article?img_id='+this.secstr3.imageId;      
          },
          error=>{
            console.log(error);   
          });

        this.apiService.secMason(secId, this.cstart, this.cend).subscribe(
          data => {
              console.log(data);
              this.msonry=data;
              this.spinnerService.hide();
              //this.carimage=this.baseImgPath+'/image/journal/article?img_id='+this.str4.imageId;      
          },
          error=>{
            console.log(error);   
            this.spinnerService.hide();
          });   

          this.apiService.oldEdition().subscribe(
            data => {
                console.log(data);
                this.oldEdi=data;
                //this.oldImage=this.baseImgPath+'/image/journal/article?img_id='+this.str7.imageId;      
            },
            error=>{
              console.log(error);   
          });   
  }



  goToStory(linkid){
    // this.router.navigate(['section'],{ queryParams: {status: 'false'}});
    this.router.navigate(['story'],{ queryParams: {link: linkid}});
  }

  secLoadMore(cstart, cend){
    //console.log(this.counter + 'dat size'+this.msonry.length);

  

    this.cstart=cend + 1;
    this.cend=this.cstart + 9;

    this.apiService.moreSecMason(this.secId, this.cstart, this.cend).subscribe(
      data => {
        this.moredata=data;
         //console.log(this.moredata);
         this.msonry = this.msonry.concat(this.moredata);

         if(this.moredata.length==0){
          this.loadmoretxt="NO MORE STORIES";
         }

         // console.log(this.msonry);
          
          //this.carimage=this.baseImgPath+'/image/journal/article?img_id='+this.str4.imageId;      
      },
      error=>{
        console.log(error);   
        
      });
  }
  

}
