import { Component, OnInit } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import {Validators, FormBuilder, FormGroup, FormControl  } from '@angular/forms';
import {AppService} from "../app.service";
import {Router,ActivatedRoute,NavigationEnd} from "@angular/router";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-digitalworkshop',
  templateUrl: './digitalworkshop.component.html',
  animations: [
    trigger(
      'enterAnimation', [
        transition(':leave', [
          style({transform: 'translateY(0)', opacity: 1}),
          animate('300ms', style({transform: 'translateY(100%)', opacity: 0}))
          
        ]),
        transition(':enter', [
          style({transform: 'translateY(100%)', opacity: 0}),
          animate('300ms', style({transform: 'translateY(0)', opacity: 1}))
        ])
      ]
    )
  ],
  styleUrls: ['./digitalworkshop.component.css']
})
export class DigitalworkshopComponent implements OnInit {
  showfrmdiv=false;
  cstart:number=0;
  linkId:any;
  cend:number=10;
  secId:any;
  sName:any;
  user:any={};
  loggedin:any=0;
  digital:any=[];
  moredata:any=[];
  uid:number=0;
  storyDetmsg:any={};
  getglp:any;
  fileMeta:any;
  successmsg:any='';
  loginmsg:any='';
  fullname:string;
  eventid:any=0;
  sucss:any=0;
  date:any;
  modalshow='false';
  gDetail:any={title:"",byline:"",content:""};
  loadmoretxt:any="LOAD MORE";
  baseImgPath ='https://api.sananda.in';
  constructor(public http: HttpClient,private datePipe: DatePipe,private apiService: AppService,private router : Router,private formBuilder: FormBuilder,public activeRoute: ActivatedRoute, private spinnerService: Ng4LoadingSpinnerService) { }

  ngOnInit() {
    this.date = this.datePipe.transform( new Date(), 'yyyy-MM-dd');
    this.linkId = this.activeRoute.snapshot.queryParams['link'];    
    this.eventid = this.activeRoute.snapshot.queryParams['eventid'];    

    this.user=JSON.parse(localStorage.getItem("user"));
    console.log(this.user);
    if(this.user){
      this.loggedin=1;
      this.uid = this.user.uid;
    }

    this.activeRoute.queryParams.subscribe(params => {
        this.secId = params['sectionid'];
        this.sName = params['secName'];
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        //location.reload();
        this.spinnerService.show(); 
        this.initialiseState(this.secId); // reset and set based on new parameter this time
     });
  } 

  initialiseState(secid){
    this.apiService.digitallist(this.uid).subscribe(
    data => {
        console.log(data);
        this.storyDetmsg = data;
        this.digital=data['events'];
        this.spinnerService.hide();
        //this.carimage=this.baseImgPath+'/image/journal/article?img_id='+this.str4.imageId;      
    },
    error=>{
      console.log(error);   
      this.spinnerService.hide();
    });
  }

  evtRegister(eventid){
    if(this.loggedin == 1){
        console.log(eventid);
        this.apiService.eventregistration(this.uid,eventid,this.date).subscribe(
          data => {
            console.log(data);
            //this.eventdetails();
            this.modalshow = 'true';
          },
          error=>{
            console.log(error);   
          });
    } else {
      this.router.navigate(['loginreg'],{ queryParams: {linkdigital: 2,linkid: this.linkId,eventid: eventid}});
    }
  }

  goToDigital(id){
    console.log(id);
    // this.router.navigate(['section'],{ queryParams: {status: 'false'}});
    this.router.navigate(['digitalinner'],{ queryParams: {link: id}});
  }

  evtJoin(eventid){
    console.log(eventid);
    this.router.navigate(['digitalvideo'],{ queryParams: {link: eventid,uid: this.uid}});
  }

}   
