import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute,NavigationEnd} from '@angular/router';

import {AppService} from "../app.service";
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  users= [];
  chgStatus=false;
  constructor(private apiService: AppService,private activeRoute: ActivatedRoute) { 
	this.chgStatus = this.activeRoute.snapshot.queryParams['status'];
	//alert(this.chgStatus);
  }

  ngOnInit() {
	  
	  
	this.apiService.getUsers().subscribe(
	   data => {this.users = data
	   
	   
	   
	   },
	   error=>{
		   console.log(error);
		   
	   }
	   
	   );
	}

}