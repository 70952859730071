import { Component, OnInit } from '@angular/core';
import {Router,ActivatedRoute,NavigationEnd} from "@angular/router";
import {AppService} from "../app.service";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { MetaService } from 'ng2-meta';
import { DatePipe } from '@angular/common';
import { trigger, style, animate, transition } from '@angular/animations';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-digitalvideo',
  templateUrl: './digitalvideo.component.html',
  animations: [
    trigger(
      'enterAnimation', [
        transition(':leave', [
          style({transform: 'translateY(0)', opacity: 1}),
          animate('300ms', style({transform: 'translateY(100%)', opacity: 0}))
          
        ]),
        transition(':enter', [
          style({transform: 'translateY(100%)', opacity: 0}),
          animate('300ms', style({transform: 'translateY(0)', opacity: 1}))
        ])
      ]
    )
  ],
  styleUrls: ['./digitalvideo.component.css']
})
export class DigitalvideoComponent implements OnInit {
  linkId:any;
  video:any={};
  usrstate:any={};
  baseImgPath ='https://api.sananda.in';
  user:any={};
  loggedin:any=0;
  uid:any=0;
  youvid:any;
  userstate:any=0;

  constructor(private router : Router, private _sanitizer: DomSanitizer, private datePipe: DatePipe,public activeRoute: ActivatedRoute, private metaService: MetaService, private apiService: AppService, private spinnerService: Ng4LoadingSpinnerService) {
   }

  ngOnInit() {
    
    this.spinnerService.show();
    this.linkId = this.activeRoute.snapshot.queryParams['link'];    
    this.uid = this.activeRoute.snapshot.queryParams['uid'];    

    this.user=JSON.parse(localStorage.getItem("user"));
    console.log(this.user);
    if(this.user){
      this.loggedin=1;
      this.uid = this.user.uid;
    } 

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      } 
      window.scrollTo(0, 0) 
  });
      this.spinnerService.show();
      this.eventdetails();
  }

  eventdetails(){
    this.apiService.digitaldetail(this.uid,this.linkId).subscribe(
      data => {
        console.log(data);
        this.usrstate = data;
        this.video = data['event'];
        this.youvid = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/'+this.video.event_video+'?rel=0&autoplay=1');
        this.userstate =  this.usrstate.userState;
        this.spinnerService.hide();
      },
      error=>{
        console.log(error);   
      });
  }

 
}
