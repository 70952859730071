import { Component } from '@angular/core';
import {Router, NavigationEnd } from "@angular/router";
import {AppService} from "./app.service";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { EventsService } from 'angular4-events';
import 'rxjs/add/operator/distinctUntilChanged';

declare var gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  //directives: [Ng4LoadingSpinnerComponent],
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  latestedi:any={};
  baseImgPath ='https://api.sananda.in';
  user;
  fullname:string;
  loggedin:any=0;
  template: string ='<img class="custom-spinner-template" src="http://pa1.narvii.com/5722/2c617cd9674417d272084884b61e4bb7dd5f0b15_hq.gif">';
  keyword:any="";
  
  constructor(private router : Router, private apiService: AppService, private spinnerService: Ng4LoadingSpinnerService, private events: EventsService) { 
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  //this.spinnerService.show();
  
  
  this.events.subscribe('user:loggedin').subscribe((user)=>{ 
    this.validateLogin();
  });
	
  
  this.apiService.latestEdition().subscribe(
    data => {
      console.log(data);
      this.latestedi=data;
       
    },
    error=>{
      console.log(error);   
    });

    this.user=JSON.parse(localStorage.getItem("user"));
    console.log(this.user);
    if(this.user){

      this.fullname=this.user.name;
      this.loggedin=1;
      
    }

  }

  ngOnInit() {
    this.router.events.distinctUntilChanged((previous: any, current: any) => {
        // Subscribe to any `NavigationEnd` events where the url has changed
        if(current instanceof NavigationEnd) {
            return previous.url === current.url;
        }
        return true;
    }).subscribe((x: any) => {
        gtag('config', 'UA-35969090-1', {'page_path': x.url});
    });
}

  validateLogin(){
    this.user=JSON.parse(localStorage.getItem("user"));
    console.log(this.user);
    if(this.user.state){

      this.fullname=this.user.name;
      this.loggedin=1;
      
    }
    }

  goToHome(){
    this.router.navigate(['home']);
  }

  goToSearch(){
    console.log(encodeURI(this.keyword));
    this.router.navigate(['search'],{ queryParams: {key: encodeURIComponent(this.keyword)}});
  }

  goTologinreg(){
    this.router.navigate(['loginreg']);
  }

  goTologout(){
    console.log('logout');   
    this.loggedin=0;
    localStorage.clear();
    this.router.navigate(['home']);
  }

  goStatic(linkid){
    this.router.navigate(['story'],{ queryParams: {link: linkid}});
  }
  
  
}
